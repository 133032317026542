import React, { useContext, useEffect, useState} from 'react';
import "./ItemCart.scss";
import { CartContext } from "../../../contexts/CartContext";
import iconRow from "../../../assets/icons/icon_row.png";
import iconClose from "../../../assets/icons/icon_close.png";
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';


const ItemCart = () =>{

    const { cart, updateCartQuantity, removeFromCart } = useContext(CartContext);
    const [subtotal, setSubtotal] = useState(0);
    //const [delivery, setDelivery] = useState(30000);
    const [total, setTotal] = useState(0);
    const { user } = useAuth();
    const navigate = useNavigate(); 

    // useEffect(()=>{
    //     setDelivery(30000);
    // }, [])

    useEffect(() => {
        if (cart && Array.isArray(cart)) {
            const subtotal0 = cart.reduce((acc, item) => acc + (item.price * item.quantity), 0);
            setSubtotal(subtotal0);
        }
    }, [cart]);
    
    useEffect(() => {
        setTotal(subtotal);
    }, [subtotal]); 
    
    const convertMoney = (money)=>{
        const formatter = new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
        });
        return formatter.format(money);
    }

    const handleCreaseQuantity = (index) =>{
        if (Array.isArray(cart)) {
            cart.forEach((item, i) => {
                if (i === index) {
                    const newQuantity = item.quantity + 1;
                    const newTotal = item.price * newQuantity; // Ensure price is a valid number
                    updateCartQuantity(index, newQuantity, newTotal); // Update cart quantity and total
                }
            });
        }   
    }

    const handleReduceQuantity = (index) => {    
        if (Array.isArray(cart)) {
            cart.forEach((item, i) => {
                if (i === index) {
                    const newQuantity = item.quantity > 1 ? item.quantity - 1 : 1;
                    const newTotal = item.price * newQuantity; // Ensure price is a valid number
                    updateCartQuantity(index, newQuantity, newTotal); // Update cart quantity and total
                }
            });
        }  
    };

    const handleRemoverCart = (index) =>{
        if (Array.isArray(cart)) {
            cart.forEach((item, i)=>{
                if(i === index){
                    removeFromCart(index);
                }
            });
        }
    }

    const handlePayment = async () =>{
        console.log(user);
        if(user === null){
            return navigate("/login");
        }else{
            return navigate("/payment");
        }
    }

    return (
        <div className="cart container">
            <div className="row">
            <p className="title-cart">Cart</p>
                <div className="col-xl-8 col-md-12">
                    <ul>
                    {cart && cart.map((item, index) => (
                        <li key={index} className="item-cart-wrapper"> 
                            <div className="row item-cart">
                                <div className="col-3 d-flex align-items-center justify-content-center">
                                    <img src={item.fontImage} alt="item"  className="img-cart"/>
                                </div>
                                <div className="col-6">
                                    <p className="item-cart-name">{item.name}</p>
                                    <p className="item-cart-cate">Asl T-shiert</p>
                                    <div className='mt-2'>
                                        <div className="item-cart-colour">{item.color}</div>
                                        <div className="item-cart-size">{item.size}</div>
                                    </div>
                                    <div className='product-container'>
                                        <p className='product-size'>Quantity</p>
                                        <div className='product-quantity'>
                                            <div className='icon-row-90' onClick={()=>{handleReduceQuantity(index)}}>
                                                <img src={iconRow} alt='iconRow'/>
                                            </div>
                                            <span>{item.quantity}</span>
                                            <div className='icon-row-180' >
                                                <img src={iconRow} alt='iconRow' onClick={()=>{handleCreaseQuantity(index)}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 d-flex justify-content-center align-items-center position-relative">
                                    <p className="item-cart-price">{convertMoney(item.total)}</p>
                                    <div className='icon-close' onClick={()=>{handleRemoverCart(index)}}>
                                        <img src={iconClose} alt='icon-close' />
                                    </div>
                                </div>
                            </div>
                        </li>
                    
                    ))}
                    </ul>
                </div>
                
                <div className="col-xl-4 col-md-12">
                    <p className="title-sumary">Summary</p>
                    <ul className="p-0">
                        <li>
                            <div className="container-fluid">
                                <div className="row details-amount d-flex justify-content-between">
                                    <div className="col-7">
                                        <p className="text-sub">Subtotal</p>
                                    </div>
                                    <div className="col-5 d-flex justify-content-end align-items-end">
                                        <p className="text-money">{convertMoney(subtotal)}</p>
                                    </div>
                                </div>
                            </div>

                        </li>
                        <li>
                            <div className="container-fluid">
                                <div className="row details-amount d-flex justify-content-between">
                                    <div className="col-7">
                                        <p className="text-sub">Total</p>
                                    </div>
                                    <div className="col-5 d-flex justify-content-end align-items-end">
                                        <p className="text-money">{convertMoney(total)}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className='d-flex justify-content-center'>
                        <button className="bt-payment" onClick={handlePayment}>Payment</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ItemCart;