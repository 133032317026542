import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import DialogGuest from '../components/Dialog/DialogGuest/DialogGuest';

// Biến lưu trữ trạng thái của Dialog và các hàm liên quan
let dialogData = {
    isOpen: false,
    title: '',
    message: '',
    onConfirm: () => {},
  };
  
let setDialogData;

// Hàm để hiển thị Dialog
export const openDialogGuest = (title, message, onConfirm) => {
    setDialogData({
      isOpen: true,
      title,
      message,
      onConfirm,
    });
};

// Hàm để đóng Dialog
export const closeDialogGuest = () => {
    setDialogData({
      ...dialogData,
      isOpen: false,
    });
};

const DialogGuestService = () =>{
    const [localData, setLocalData] = useState(dialogData);
    setDialogData = setLocalData; // Gán setDialogData để cập nhật trạng thái
    if (!localData.isOpen) return null; // Không hiển thị khi isOpen = false

    return ReactDOM.createPortal(
        <DialogGuest title={localData.title} message={localData.message} onClose={localData.onConfirm != null ? localData.onConfirm : closeDialogGuest} />,
        document.body
    )
}

export default DialogGuestService;