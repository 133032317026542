import React, { useState, useEffect, useCallback } from "react";
import './ListCollection.scss'
import axios from "axios";
import Pagination from "../../Guest/Pagination/Pagination";
import { useAuth } from "../../../contexts/AuthContext";
import { openDialog } from "../../../service/DialogService";
import { openDialogWaitingAdmin, closeDialogWaitingAdmin } from "../../../service/DialogWatingAdminService";

const ListCollection = () =>{

    const [isCreateCollection, setIsCreateCollection] = useState(false);
    const [isAlert, setIsAlert] = useState(false);
    const {  logout } = useAuth();
    const [collection, setCollection] = useState(null);
    const [message, setMessage] = useState("");

    const handleAddCollection = (e) => {
        e.preventDefault();
        // Toggle the state or perform some action
        setCollection(null);
        setNameCollection("");
        setIsAlert(false);
        setIsCreateCollection(prev => !prev);
    };

    const [nameCollection, setNameCollection] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        openDialogWaitingAdmin();
        const token = localStorage.getItem('token');
        try {
            let response;
            if(collection === null)
            {
                response = await axios.post(process.env.REACT_APP_HOST + '/collections', {
                    name: nameCollection,
                    token : token
                });
            }else
            {
                response = await axios.put(process.env.REACT_APP_HOST + '/collections/' + collection.id, {
                    name: nameCollection,
                    token : token
                });
            }

            // Handle the response, such as showing a success message or redirecting
            console.log('Collection created:', response);
            setMessage(response.data.message);
            setIsAlert(true);
            setCollection(response.data.collection);
            closeDialogWaitingAdmin();
        } catch (error) {
            // Handle errors, such as showing an error message
                if (error.response) {
                    if(error.response.status === 401){
                        openDialog("Thông báo", "Phiên đăng nhập hết hạn" , logout);
                    }
                 
                } else {
                    console.log('Error creating collection:', error.message);
                    alert('Đã xảy ra lỗi khi tạo bộ sưu tập.');
                }
        }
    };


    const [collections, setCollections] = useState([]);
    const [page, setPage] = useState(1); // Quản lý trạng thái của trang hiện tại
    const [amountPage, setAmountPage] = useState(3);

    const fetchCollections = useCallback(async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_HOST + '/getAllCollections', {
                params: { page } // Pass page as query parameter
            });

            // Calculate total pages
            const totalPages = Math.ceil(response.data.total / response.data.limit);
            // Update state
            setAmountPage(totalPages);
            setCollections(response.data.collections);
            closeDialogWaitingAdmin();
        } catch (error) {
            console.error('Lỗi khi lấy bộ sưu tập:', error);
            // Handle error, e.g., show an error message
        }
    }, [page]); // `page` is a dependency because it influences the request

    useEffect(() => {
        openDialogWaitingAdmin();
        fetchCollections(); // Fetch collections when the component loads or page changes
    }, [fetchCollections]);

    const handleBack = (e) => {
        e.preventDefault();
        setIsCreateCollection(prev => !prev);
        fetchCollections(); // Call fetchCollections manually when needed
    };

    const onPageChange = (page)=>{
        setPage(page);
    }

    const setInputName = (e)=>{
        setNameCollection(e.target.value)
        setIsAlert(false);
    }

    const fetchCollection = async (id) => {
        try {
            const response = await axios.get(process.env.REACT_APP_HOST + '/collections/' + id);
            setNameCollection(response.data.name);
            setCollection(response.data);
            closeDialogWaitingAdmin();
        } catch (error) {
            if (error.response) {
                if(error.response.status === 401){
                    openDialog("Thông báo", "Phiên đăng nhập hết hạn" , logout);
                }  
            }
            console.log("Error fetching collections: ", error);
        }
    };

    const handleUpdateCollection = (id) => {
        setIsCreateCollection(prev => !prev);
        setIsAlert(false);
        openDialogWaitingAdmin();
        fetchCollection(id);
    };

    return (
        <>
            {isCreateCollection ? (
            <div className="from-create-collection">
                <h2>Tạo bộ sưu tập</h2>
                {/* Add your form or other content here */}
                <div className="row">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3 col-xxl-6">
                            <label htmlFor="name-collection" className="form-label">Tên bộ sưu tập</label>
                            <input type="text" className="form-control" value={nameCollection} onChange={setInputName}  />
                            {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                        </div>
                        <div className={`alert alert-success col-xxl-6 ${isAlert ? '' : 'd-none'}`} role="alert">
                            {message}
                        </div>
                        <button className="btn btn-secondary m-1" onClick={handleBack}>Quay lại</button>
                        <button type="submit" className="btn btn-primary m-1">{collection == null ? 'Tạo' : "Sửa"}</button>
                    </form>
                </div>
            </div>
        ) : (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xxl-6">
                        <button className="bt-add" onClick={handleAddCollection}>Thêm bộ sưu tập</button>
                    </div>
                    <div className="col-xxl-6">
                        {/* Phần search */}
                    </div>
                    <div className="col-xxl-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Tên bộ sưu tập</th>
                                    <th scope="col">Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                            {collections && collections.map((collection) => (
                                <tr key={collection.id}>
                                    <th scope="row" className="align-middle">{collection.id}</th>
                                    <td className="align-middle">{collection.name}</td>
                                    <td className="align-middle">
                                        <button type="button" className="btn btn-warning m-1" onClick={()=> handleUpdateCollection(collection.id)}>Sửa</button>
                                        {/* <button type="button" className="btn btn-danger m-1">Xóa</button> */}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <Pagination currentPage={page} amountPage={amountPage} onPageChange={onPageChange} />
                    </div>
                </div>
            </div>
        )}
        </>
    );
}

export default ListCollection;