import React, { forwardRef, useEffect, useState } from 'react';
import "./ListProduct.scss";
import Pagination from '../Pagination/Pagination';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ListProduct = forwardRef(({ isOpenFilter }, ref) =>{

    const [listItems, setListItems] = useState([]);
    const [page, setPage] = useState(1); // Quản lý trạng thái của trang hiện tại
    const [amountPage, setAmountPage] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_HOST + '/getListItems', {
                    params: { page } // Truyền tham số page vào yêu cầu
                });

                // Tính tổng số trang
                const totalPages = Math.ceil(response.data.total / response.data.limit);
                // Cập nhật số trang và dữ liệu bộ sưu tập
                setAmountPage(totalPages);
                setListItems(response.data.items);

            } catch (error) {
                console.error('Lỗi khi lấy bộ sưu tập:', error);
                // Xử lý lỗi, chẳng hạn như hiển thị thông báo lỗi
            }
        };

        fetchItems(); // Lấy dữ liệu bộ sưu tập khi component được tải
    }, [page]);

    const onPageChange = (page)=>{
        setPage(page);
    }

    const hanleClickItem = (id)=>{
        navigate('/product/'+ id);
    }

    const convertMoney = (money)=>{
        const formatter = new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
        });
        return formatter.format(money);
    }

    const [hoveredIndex, setHoveredIndex] = useState(null);

    return (
        <>
            <div ref={ref} className='list-product'>
                <div className= {`${isOpenFilter ? 'container-fluid' : 'container'}`}>
                    <div className="row justify-content-center d-flex mt-3">
                    {listItems && listItems.map((item, index) => (
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-6 padding-item" key={index}>
                            <div className="item" onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)} 
                            onClick={()=> hanleClickItem(item.id)}>
                                <img src={process.env.REACT_APP_UPLOAD +(hoveredIndex === index ? item.backImage : item.fontImage)} alt="item" 
                                className={`img-item` }/>
                            </div>
                            <p className="pt-2 name-item">{item.name}</p>
                            <p className="name-collection">{item.Collection.name}</p>
                            <p className="price">{convertMoney(item.price)}</p>
                        </div>
                    ))}
                    </div>
                </div>
                <Pagination currentPage={page} amountPage={amountPage} onPageChange={onPageChange} />
            </div>
        </>
    );
});

export default ListProduct;