import React, {useState,  useRef,  useEffect} from "react";
import { Outlet } from 'react-router-dom';
import HeaderAdmin from '../../components/Admin/HeaderAdmin/HeaderAdmin';
import FunctionAdmin from '../../components/Admin/FunctionAdmin/FunctionAdmin';

const AdminDashboard = () =>{
    const [isOpenFilterAdmin, setIsOpenFilterAdmin] = useState(false);

    const toggleSidebarAdmin = () => {
      setIsOpenFilterAdmin(!isOpenFilterAdmin);
    };

    const transformAdminRef = useRef(null);
    const ref_filter = useRef(null);
    const functionAdminWidth = useRef(250); // Chiều rộng cố định của FunctionAdmin
  
    useEffect(() => {
        if(ref_filter.current){
            const { width } = ref_filter.current.getBoundingClientRect();
            functionAdminWidth.current = width; // Update the ref
        }

        if (transformAdminRef.current) {
            const transformAdmin = transformAdminRef.current;
            const widthAdjustment = isOpenFilterAdmin ? functionAdminWidth.current : 0; // Điều chỉnh chiều rộng dựa trên isOpenFilterAdmin
            transformAdmin.style.width = `calc(100% - ${widthAdjustment}px)`;
            const xTransform = isOpenFilterAdmin ? functionAdminWidth.current : 0;
            transformAdmin.style.transform = `translateX(${xTransform}px)`
        }
    }, [isOpenFilterAdmin]);
  
    return (
        <>
            {/* Sidebar, Header, etc. */}
            <HeaderAdmin />
            <FunctionAdmin refSlideBar={ref_filter} isOpenFilterAdmin={isOpenFilterAdmin} toggleSidebarAdmin={toggleSidebarAdmin}/>
            <div ref={transformAdminRef} className={`transform-admin ${!isOpenFilterAdmin ? 'container' : ''}`} >
                <Outlet /> {/* Render các route con */}
            </div>
        </>
    );
}

export default AdminDashboard;