import React, { useState } from 'react';
import './Search.scss'; // Import CSS if needed
import search from '../../../assets/icons/icon_search.png';
const Search = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSearch = () => {
    if (onSearch) {
      onSearch(query);
    }
  };

  return (
    <div className="search-container">
    <div className="search-wrapper">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="Search..."
        className="search-input"
      />
      <img
        src={search}
        onClick={handleSearch}
        alt="Search"
        className="icon-search"
      />
    </div>
  </div>
  );
};

export default Search;
