import React from 'react';
import './DialongYesNoAdmin.scss';

const DialogYesNoAdmin = ({message, onYes, onNo }) => {

    return (
        <div  className='dialog-yes-no-admin'>
            <div className='dialog'>
                <h2>
                    Xin chờ
                </h2>
                <div className='text'>
                    <p>{message}</p>
                </div>
                <div className='button-dialog'>
                    <button type="button" className="btn btn-secondary" onClick={onNo}>No</button>
                    <button type="button" className="btn btn-success" onClick={onYes}>Yes</button>
                </div>
            </div>
        </div>
    );
};


export default DialogYesNoAdmin;
