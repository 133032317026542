import React, {useContext, useState} from "react";
import "./DetailsPayment.scss";
import axios from "axios";
import { openDialogWaiting, onSuccess } from "../../../service/DialogWaitingService";
import { useAuth } from "../../../contexts/AuthContext";
import { CartContext } from "../../../contexts/CartContext";

const DetailsPayment = ()=>{

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [noti, setNoti] = useState("");
    const {user} = useAuth();
    const {cart} = useContext(CartContext);

    const handleInputNumric = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { // Only allow digits
            setPhone(value);
        }
    };

    const [validEmail, setValidEmail] = useState('');
    const [validName, setValidName] = useState('');
    const [validPhone, setValidPhone] = useState('');
    const [validAddress, setValidAddress] = useState('');

    const resetValid = ()=>{
        setValidName("");
        setValidEmail("");
        setValidPhone("");
        setValidAddress("");
    }

    const handleClickOrder = async ()=>{
        resetValid();
        let isValid = false;
        if(email ===""){
            setValidEmail("Bạn chưa nhập email");
            isValid = true;
        }
        if(name === ""){
            setValidName("Bạn chưa nhập tên");
            isValid = true;
        }
        if(phone === ""){
            setValidPhone("Bạn chưa nhập số điện thoại");
            isValid = true;
        }
        if(address === ""){
            setValidAddress("Bạn chưa nhập địa chỉ");
            isValid = true;
        }
        if(isValid)
            return;

        openDialogWaiting(null);
        onSuccess();
        let total = 0;
        if (cart && Array.isArray(cart)) {
            total = cart.reduce((acc, item) => acc + (item.price * item.quantity), 0);
        }

        try{
            const response = await axios.post(process.env.REACT_APP_HOST + '/receipts', {
                name: name,
                email : email,
                phone : phone,
                address: address,
                notification : noti,
                userId : user.id,
                total: total,
                items : cart
            });
            if(response.data.message === 'Create success!!!')
                onSuccess();

        }catch(error){
            console.log(error);
        }

    }

    return(
        <div className="container details-payment">
            <div className="row">
                <div className="col-6">
                    <p className="detail-title-buy">Thông tin mua hàng</p>
                    <div className="p-2">
                        <input type="email" className="form-control input-detail" placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
                        <div className="form-text mx-1 text-custom-red">{validEmail}</div>
                    </div>
                    <div className="p-2">
                        <input type="text" className="form-control input-detail" placeholder="Tên" onChange={(e) => setName(e.target.value)}/>
                        <div className="form-text mx-1 text-custom-red">{validName}</div>
                    </div>
                    <div className="p-2">
                        <input type="text" className="form-control input-detail" placeholder="Số điện thoại" value={phone}  onChange={handleInputNumric}/>
                        <div className="form-text mx-1 text-custom-red">{validPhone}</div>
                    </div>
                    <div className="p-2">
                        <input type="text" className="form-control input-detail" placeholder="Địa chỉ" onChange={(e) => setAddress(e.target.value)}/>
                        <div className="form-text mx-1 text-custom-red">{validAddress}</div>
                    </div>
                    <div className="form-group p-2">
                        <textarea className="form-control input-detail" rows="3" placeholder="Ghi chú" onChange={(e) => setNoti(e.target.value)}></textarea>
                    </div>

                </div>
                <div className="col-6">
                    <div className="container-fluid">
                        <p className="detail-title-buy">Vận chuyển</p>
                        <div className="tag-delivery d-flex align-items-center justify-content-center text-center">
                            <p>Vui lòng nhập thông tin giao hàng</p>
                        </div>
                        <p className="detail-title-buy">Thanh toán</p>
                        <ul className="ul-payment">
                            <li>
                                <div className="p-1">
                                    <div className="form-check form-method-payment px-2 pt-4 pb-4">
                                        <input type="checkbox" className="form-check-input mx-3"/>
                                        <label className="form-check-label text-payment-method" htmlFor="exampleCheck1">Thanh toán khi nhận hàng</label>
                                    </div>
                                </div>
                            </li>
                            {/* <li>
                                <div className="p-1">
                                    <div class="form-check form-method-payment px-2 pt-4 pb-4">
                                        <input type="checkbox" class="form-check-input mx-3"/>
                                        <label class="form-check-label text-payment-method" htmlFor="exampleCheck1">Momo</label>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="justify-content-center d-flex">
                <button className="bt-order" onClick={handleClickOrder}>Đặt hàng</button>
            </div>
        </div>
    );
}

export default DetailsPayment;
