import React from 'react';
import './DialogWaitingAdmin.scss';

const DialogWaitingAdmin = () => {

    return (
        <div  className='dialog-waiting-admin'>
            <div className='dialog'>
                <h2>
                    Xin chờ
                </h2>
                <div className="loader"></div>
            </div>
        </div>
    );
};


export default DialogWaitingAdmin;
