import React, { createContext, useState, useEffect } from 'react';

const CartContext = createContext();

const CartProvider = ({ children }) => {

    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(true);

    // Khôi phục giỏ hàng từ localStorage khi trang load lại
    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem('cart'));
        if (storedCart) {
            setCart(storedCart);
        }
        setLoading(false);
    }, []);

    useEffect(() =>{
        if(!loading){
            localStorage.setItem('cart', JSON.stringify(cart));
        }
    }, [cart, loading])
    
    const addToCart = (product) => {
        setCart((prevCart) => [...prevCart, product]);        
    };

    const removeFromCart = (index) => {
        setCart((prevCart) => {
            const updatedCart = prevCart.filter((item, i) => i !== index);
            localStorage.setItem('cart', JSON.stringify(updatedCart)); // Update localStorage after filtering
            return updatedCart; // Return the updated cart
        });
    };

    const updateCartQuantity = (index, quantity, total) => {
        setCart((prevCart) => prevCart.map((item, i )=>
            i === index ? { ...item, quantity: quantity, total: total} : item
        ));
        localStorage.setItem('cart', JSON.stringify(cart));
    };

    if (loading) {
        return <div>Loading...</div>; // Hiển thị loading khi đang tải dữ liệu
      }

    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCart, updateCartQuantity }}>
            {children}
        </CartContext.Provider>
    );
};

export { CartContext, CartProvider };