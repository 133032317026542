import React from 'react';
import './DialogOK.scss';

const DialogOK = ({ title, message, onClose }) => {

    return (
        <div  className='dialog-OK'>
        <div className='dialog'>
            <h2>{title}</h2>
            <div className='text'>
                <p>{message}</p>
            </div>
            <div className='actions'>
                <button className='button' onClick={onClose}> OK </button>
            </div>
        </div>
        </div>
    );
};


export default DialogOK;
