import React from "react";
import "./HeaderAdmin.scss";
import logo from "../../../assets/logo.png";
import user from "../../../assets/icons/icon_user.png";

const HeaderAdmin = () =>{
    return (
        <header className="header-admin">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xxl-6 align-items-start d-flex justify-content-start margin-header">
                        <div className="custom-height">
                            <img src={logo} alt="logo" className="logo" />
                        </div>
                    </div>
                    <div className="col-xxl-6 d-flex justify-content-end align-items-center">
                        <p className="text-name">Admin</p>
                        <img src={user} alt="user" className="user-img" />
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HeaderAdmin;