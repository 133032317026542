import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import DialogYesNoAdmin from '../components/Dialog/DialogYesNoAdmin/DialogYesNoAdmin';

// Declare dialog data and its setter inside the component to avoid global issues
let setDialogData;

// Function to open the Dialog
export const openDialogYesNoAdmin = (message, onYes) => {
    if (setDialogData) {
        setDialogData({
            isOpen: true,
            message,
            onYes 
        });
    }
};

// Function to close the Dialog
export const closeDialogYesNoAdmin = () => {
    if (setDialogData) {
        setDialogData({
            isOpen: false
        });
    }
};

const DialogYesNoAdminService = () => {
    const [dialogData, setLocalData] = useState({ isOpen: false, message: "" });
    setDialogData = setLocalData; // Assign setLocalData to the global setDialogData

    const onNo = ()=>{
      setDialogData({isOpen: false});
    }

    if (!dialogData.isOpen) return null; // Return null when isOpen is false

    return ReactDOM.createPortal(
        <DialogYesNoAdmin 
          message={dialogData.message} 
          onYes={() => {
            dialogData.onYes(); // Gọi hàm onYes đã truyền vào
            setLocalData({ isOpen: false }); // Đóng dialog sau khi onYes thực thi
          }}
          onNo={onNo} />,
        document.body
    );
};

export default DialogYesNoAdminService;
