import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsProduct from '../../components/Guest/DetailsProduct/DetailsProduct';

const Product = () =>{
    const { id } = useParams(); // Lấy tham số `id` từ URL
    return (
        <div>
            <DetailsProduct id={id}/>
        </div>
    );
}

export default Product;