import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Thêm trạng thái loading
  const navigate = useNavigate();

  useEffect(() => {

    const checkTokenAndFetchUser = async (token) => {
      if (token) {
        try {
          await axios.get(process.env.REACT_APP_HOST + '/checktoken', {
            params: {
              token: token  // Truyền token dưới dạng query parameter
            }
          });
        } catch (error) {
          if(error.response.status === 401){
            localStorage.removeItem('token');
            localStorage.removeItem('users');
            setUser(null);
          }
        }
      }
    }

    // Kiểm tra token từ localStorage và xác thực người dùng
    const token = localStorage.getItem('token');
    if (token) {
      checkTokenAndFetchUser(token);
    }
    const users = localStorage.getItem('users');
    if(users){
      setUser(JSON.parse(users));
    }
    setLoading(false); // Kết thúc trạng thái loading sau khi dữ liệu được tải
  }, []);

  
  const loginAuth = async (token, user) => {
    try {

      // Lưu token vào localStorage
      localStorage.setItem('token', token);
      const usersJSON = JSON.stringify(user);
      localStorage.setItem('users', usersJSON);
      setUser(usersJSON);
      if(user.role === "admin")
      {
        navigate('/admin');
      }
      
    } catch (error) {
      console.error('Đăng nhập thất bại:', error.response ? error.response.data : error.message);
      // Xử lý lỗi đăng nhập (hiển thị thông báo lỗi, v.v.)
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('token');
    navigate('/login'); // Điều hướng đến trang đăng nhập
  };

  if (loading) {
    return <div>Loading...</div>; // Hiển thị loading khi đang tải dữ liệu
  }

  return (
    <AuthContext.Provider value={{ user, loginAuth, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
