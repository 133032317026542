import React from "react";
import "./Carousel.scss"; // Import custom CSS if needed
import baner1 from '../../../assets/banners/banner1.jpg';
import baner2 from '../../../assets/banners/banner2.jpg';
import baner3 from '../../../assets/banners/banner3.jpg';
const Carousel = () => {

  return (
    <div className="container-fluid">
            <div id="carouselExampleIndicators" className="carousel slide z-0" data-bs-ride="carousel">
    <div className="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div className="carousel-inner">
      <div className="carousel-item active">
        <img src={baner1} className="d-block w-100" alt="banner" />
      </div>
      <div className="carousel-item">
        <img src={baner2} className="d-block w-100" alt="banner1" />
      </div>
      <div className="carousel-item">
        <img src={baner3} className="d-block w-100" alt="banner2" />
      </div>
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Next</span>
    </button>
  </div>

    </div>

  );
};

export default Carousel;
