import React, {useState, useRef, useEffect} from 'react';
import Filter from '../../components/Guest/Filter/Filter';
import ListProduct from '../../components/Guest/ListProduct/ListProduct';

const Collection = () => {
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const toggleSidebar = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const transformRef = useRef(null);
  const ref_slide_bar = useRef(null);
  const refWidth = useRef(250); // Chiều rộng cố định của FunctionAdmin

  useEffect(() => {
      if(ref_slide_bar.current){
          const { width } = ref_slide_bar.current.getBoundingClientRect();
          refWidth.current = width; // Update the ref
      }

      if (transformRef.current) {
          const transform = transformRef.current;
          if (window.innerWidth <= 576)
            return;
          const widthAdjustment = isOpenFilter ? refWidth.current : 0; // Điều chỉnh chiều rộng dựa trên isOpenFilterAdmin
          transform.style.width = `calc(100% - ${widthAdjustment}px)`;
          const xTransform = isOpenFilter ? refWidth.current : 0;
          transform.style.transform = `translateX(${xTransform}px)`
      }
  }, [isOpenFilter]);
  
  return (
    <div className='d-flex'>
      <Filter ref={ref_slide_bar} isOpenFilter={isOpenFilter} toggleSidebar={toggleSidebar}/>
      <ListProduct ref={transformRef} isOpenFilter={isOpenFilter} />
      
    </div>
  );
};

export default Collection;