import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Routes, Route } from 'react-router-dom';
import AdminDashboard from '../pages/Admin/AdminDashboard';
import CollectionAdmin from '../pages/Admin/CollectionAdmin';
import ItemAdmin from '../pages/Admin/ItemAdmin';
import DialogService from '../service/DialogService';
import DialogWaitingAdminServie from '../service/DialogWatingAdminService';
import DialogYesNoAdminServie from '../service/DialogYesNoAdminService';

const AdminRoutes = () => {
  const { user } = useAuth();
  let userObject = null;

  if (!user) {
    return <div>Loading...</div>; // Hiển thị loading hoặc chuyển hướng khi user chưa được xác thực
  }


  if (typeof user === 'string') {
    try {
      userObject = JSON.parse(user); // Chuyển đổi chuỗi JSON thành đối tượng
    } catch (error) {
      console.error('Lỗi khi chuyển đổi chuỗi JSON thành đối tượng:', error);
    }
  } else {
    userObject = user;
  }

  if (!userObject || userObject.role !== 'admin') {
    return <Navigate to="/login" />;
  }



  return (
    <>
      <DialogService />
      <DialogWaitingAdminServie />
      <DialogYesNoAdminServie />
      <Routes>
      {/* Define admin routes here */}
        <Route path="/" element={<AdminDashboard />}>
          <Route path="collection" element={<CollectionAdmin />} />
          <Route path="item" element={<ItemAdmin />} />
        </Route>
    </Routes>
    </>

  );
};

export default AdminRoutes;
