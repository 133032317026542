import React from "react";
import './FunctionAdmin.scss';
import iconArrow from '../../../assets/icons/icon_arrow.png';

const FunctionAdmin = ({ refSlideBar, isOpenFilterAdmin, toggleSidebarAdmin }) =>{

    // const [collections, setCollections] = useState([]);
    // const [idFocus, setIdFocus ] = useState(0);

    // useEffect(() => {
    //     const fetchCollection = async (id) => {
    //         try {
    //             const response = await axios.get(process.env.REACT_APP_HOST + '/getCollectionsFunction');
    //             setCollections(response.data.collections);
    //         } catch (error) {
    //             console.error("Error fetching items: ", error);
    //         }
    //     };

    //     fetchCollection();       
    //   }, []);

    // const handleChangeIdFocus = (index) =>{
    //     setIdFocus(index);
    // }

    return(
        <>
        <button className="toggle-button" onClick={toggleSidebarAdmin}>  ☰ </button>
        <div ref={refSlideBar} className={`sidebar-admin ${isOpenFilterAdmin ? 'open' : ''}`}>
            <h3>Dashboard</h3>
            <ul>
                <li>
                    <div className='w-100 justify-content-between d-flex align-items-center' >
                        <p><a href="/admin/collection">Collection</a></p>
                        <img src={iconArrow} alt='iconArrow' className='icon-arrow' />
                    </div>
                </li>
               
                <li>
                    <div className='w-100 justify-content-between d-flex align-items-center' >
                        <p><a href="/admin/item">Items</a></p>
                        <img src={iconArrow} alt='iconArrow' className='icon-arrow' />
                    </div>
                    {/* <div className={`w-100 mx-2 horizontal-container` }>
                    {collections && collections.map((collection) => (
                        <p key={collection.id} className={`${idFocus === collection.id ? 'isFocus' : ''}`}  onClick={(e) => handleChangeIdFocus(collection.id)}>{collection.name}</p>
                        ))}
                    </div> */}
                </li>
                    
                <li>
                    <div className='w-100 justify-content-between d-flex align-items-center' >
                        <p><a href="/admin/collection">Orders</a></p>
                        <img src={iconArrow} alt='iconArrow' className='icon-arrow' />
                    </div>
                </li>
            </ul>
        </div>
        </>
    );
}

export default FunctionAdmin;