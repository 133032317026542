import React, {useState, useEffect,  useContext} from 'react';
import "./DetailsProduct.scss";
import rectHuge from '../../../assets/rect_huge.png';
import axios from 'axios';
import { CartContext } from '../../../contexts/CartContext';
import { openDialogGuest } from '../../../service/DialogGuestService';
import iconRow from '../../../assets/icons/icon_row.png';


const DetailsProduct = ({id}) =>{

    const [item, setItem] = useState(null);
    const { addToCart } = useContext(CartContext); 
    const [quantity, setQuantity] = useState(1);

    
    const fetchItem = async (id) => {
        try {
            const response = await axios.get(process.env.REACT_APP_HOST + '/items/' + id);
            setItem(response.data);
        } catch (error) {
            console.error("Error fetching items: ", error);
        }
    };

    useEffect(() =>{
        if (id) {
            fetchItem(id);
        }
    }, [id])

    const [urls, setUrls] = useState([]);
    const [listSizes, setListSizes] = useState([]);
    const [listColors, setListColors] = useState([]);
    const [imgMain, setImgMain] = useState(null);

    useEffect(() => {
        if (item && item.listImage) {
            const arrayImage = item.listImage.split(',');
            const updatedImageList = arrayImage.map(image => `${process.env.REACT_APP_UPLOAD}${image}`);
            setImgMain(updatedImageList[0]);
            setUrls(updatedImageList);
            const arraySizes = item.sizes.split(',');
            setListSizes(arraySizes);
            const arrayColors = item.colors.split(',');
            setListColors(arrayColors);
        }
    }, [item]);

    const handleClickImg = (url) =>{
        setImgMain(url);
    }

    const [size, setSize] = useState(null);
    const hadleUpdateSize = (size) =>{
        setSize(size);
    }

    const [color, setColor] = useState(null);
    const handleUpdatecolor = (color) =>{
        setColor(color);
    }

    const handleAddToCart = () =>{
        if(size === null){
            openDialogGuest("Thông báo", "Bạn chưa chọn size", null);
            return;
        }
        if(color === null){
            openDialogGuest("Thông báo", "Bạn chưa chọn màu ", null);
            return;
        }
        const product = {
            itemId: id,
            name: item.name,
            price: item.price,
            size: size,
            color: color,
            quantity : quantity,
            total: quantity * item.price,
            fontImage : process.env.REACT_APP_UPLOAD + item.fontImage
        };
        addToCart(product);
    }

    const handleIcreaseQuantity = () =>{
        setQuantity(prevQuantity => prevQuantity + 1);
    }

    const handleReduceQuantity = () =>{
        setQuantity(prevQuantity => Math.max(prevQuantity - 1, 1));
    }

    const convertMoney = (money)=>{
        const formatter = new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
        });
        return formatter.format(money);
    }

    return (
        <div className='coitainer-fluid details-product'>
            <div className='row back-ground'>
                {item ? (
                    <>
                     <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12'>
                        <ul>
                        {urls.map((url, index) => (
                            <li key={index} className='item-center' onClick={() => handleClickImg(url)}>
                                <img src={url} alt={`img-${index}`} className='img-small img-thumbnail' />
                            </li>
                        ))}
                        </ul>
                    </div>

                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 center-img-main'>
                        <img src={imgMain ? imgMain : rectHuge} alt='img' className='img-main img-thumbnail' />
                    </div>

                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                        <p className='product-name'>{item.name}</p>
                        <p className='product-cate'>{item.Collection.name}</p>
                        <p className='product-price'>{convertMoney(item.price)}</p>
                        <div className='list-img'>
                            <ul className='d-flex justify-content-start p-0 m-0'>
                            {urls.map((url, index) => (
                                <li key={index} className='img-detail'>
                                    <img src={url} alt={`img-${index}`} className='img-right img-thumbnail'/>
                                </li>

                            ))}
                            </ul>
                        </div>

                    <p className='product-size'>Size</p>
                    <div className='list-size'>
                        <ul className='d-flex justify-content-start p-0 m-0'>
                        {listSizes.map((size0, index) => (
                            <li key={index}>
                                <button className={`bt-size ${size === size0 ? 'focus' : ''}`} onClick={() => hadleUpdateSize(size0)}>{size0}</button>
                            </li>

                        ))}
                        </ul>
                    </div>

                    <p className='product-size'>Colors</p>
                    <div className='list-size'>
                        <ul className='d-flex justify-content-start p-0 m-0'>
                        {listColors.map((color0, index) => (
                            <li key={index}>
                                <button className={`bt-size ${color === color0 ? 'focus' : ''}`} onClick={() => handleUpdatecolor(color0)}>{color0}</button>
                            </li>

                        ))}
                        </ul>
                    </div>

                    <div className='product-container'>
                        <p className='product-size'>Quantity</p>
                        <div className='product-quantity'>
                            <div className='icon-row-90'>
                                <img src={iconRow} alt='iconRow' onClick={handleReduceQuantity}/>
                            </div>
                            <span>{quantity}</span>
                            <div className='icon-row-180' onClick={handleIcreaseQuantity}>
                                <img src={iconRow} alt='iconRow' />
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-column justify-content-center center-img-main'>
                        <button className='bt-add-to-bag' onClick={handleAddToCart}>Add to bag</button>
                        <button className='bt-favorite'>Favorite</button>
                    </div>
                </div>
                </>
                ) : (
                    <p>Loading...</p>
                )}
               

            </div>

            <div className='description-product'>
                <div className='description-tittle' >
                    <h2>MÔ TẢ SẢN PHẨM</h2>
                </div>
                <div className='description-tittle'>
                {item && item.description ? (
                    <p dangerouslySetInnerHTML={{ __html: item.description.replace(/\r\n|\r/g, "<br />") }}></p>
                ) : null}
                     
                </div>
            </div>
        </div>
    );
}

export default DetailsProduct;