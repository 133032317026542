import React from "react";
import "./TopCollection.scss"; // Import CSS if needed
import item from "../../../assets/item.jpg";

const TopCollection = () => {
  return (
    <div className="top-collection">
      <div className="container">
        <div className="w-100 text-center">
          <h2>Sản phẩm mới</h2>
        </div>

        <div className="row justify-content-center d-flex">
          <div className="col-md-3 col-sm-6 col-6">
            <div className="item">
                <img src={item} alt="item" className="img-item"/>
            </div>
            <p className="text-center p-2">ASL CAMPING T-SHIRT</p>
            <p className="text-center price">$100</p>
          </div>
          <div className="col-md-3 col-sm-6 col-6">
            <div className="item">
                <img src={item} alt="item" className="img-item"/>
            </div>
            <p className="text-center p-2">ASL CAMPING T-SHIRT</p>
            <p className="text-center price">$100</p>
          </div>
          <div className="col-md-3  col-sm-6 col-6">
            <div className="item">
                <img src={item} alt="item" className="img-item"/>
            </div>
            <p className="text-center p-2">ASL CAMPING T-SHIRT</p>
            <p className="text-center price">$100</p>
          </div>
          <div className="col-md-3  col-sm-6 col-6">
            <div className="item">
                <img src={item} alt="item" className="img-item"/>
            </div>
            <p className="text-center p-2">ASL CAMPING T-SHIRT</p>
            <p className="text-center price">$100</p>
          </div>
        </div>

        <div className="flex-container">
          <a href="/collection">
            <button>Seemore</button>
          </a>
        </div>

      </div>
    </div>
  );
};

export default TopCollection;
