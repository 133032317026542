// Footer.js
import React from 'react';
import './Footer.scss'; // Import custom CSS for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h5>About Us</h5>
            <p>We are a company that provides excellent services and products.</p>
          </div>
          <div className="col-md-3">
            <h5>Quick Links</h5>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </div>
          <div className="col-md-3">
            <h5>THDT</h5>
            <ul>
              <li><a href="/">Shoppee</a></li>
              <li><a href="/about">Lazada</a></li>
              <li><a href="/contact">Tiki</a></li>
            </ul>
          </div>
          <div className="col-md-3">
            <h5>Contact Us</h5>
            <p>Email: info@company.com</p>
            <p>Phone: +123 456 7890</p>
            <p>Address: 520 Trường Trinh, Quận 12, Hồ Chí Minh</p>
          </div>
        </div>
        <div className="text-center">
          <p>&copy; 2024 Your Company Name. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
