import React, {useState} from 'react';
import Login from '../components/Login/Login';
import Register from '../components/Register/Register';
import DialogWaitingAdminServie from '../service/DialogWatingAdminService';


const LoginPage = () => {

  const [isOpenLogin, setIsOpenLogin] = useState(true);

  const handleOnchangeLogin = (isLogin) =>{
    setIsOpenLogin(isLogin);
  }

  return (
    <>
      <DialogWaitingAdminServie />
      <div className='nav-login-left'>
        <div className='mb-1'>
          <p className='text-nav-left' onClick={(e) => handleOnchangeLogin(true)}>Sign in</p>
        </div>
        <div className="divider"></div>
        <div className='mt-1'>
          <p className='text-nav-left' onClick={(e) => handleOnchangeLogin(false)}>Sign up</p>
        </div>
      </div>

      <div className='nav-login-right'>
        <ul>
          <li className='text-nav-right'>Vi</li>
          <li className='text-nav-right'>En</li>
        </ul>
      </div>

      {isOpenLogin ? (<Login />) : (<Register />)}

    </>

  );
};

export default LoginPage;
