import React from "react";
import DetailsPayment from "../../components/Guest/DetailsPayment/DetailsPayment";

const Payment = () =>{
    return (
        <div>
            <DetailsPayment />
        </div>
    );
}

export default Payment;