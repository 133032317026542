import React from 'react';
import Carousel from '../../components/Guest/Carouseul/Carousel';
import TopCollection from '../../components/Guest/TopCollection/TopCollection';
import Background from '../../components/Guest/Background/Background';

const Home = () => {
  return (
    <div>
      <Carousel />
      <TopCollection />
      <Background />
      <TopCollection />
    </div>
  );
};

export default Home;