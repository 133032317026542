import React, {useState} from "react";
import './Login.scss';
import logo from '../../assets/logo.png';
import iconUser from '../../assets/icons/icon_user.png';
import iconGoogle from '../../assets/icons/icon_google.png';
import iconApple from '../../assets/icons/icon_apple.png';
import iconFace from '../../assets/icons/icon_facebook.png';
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
import { openDialogWaitingAdmin, closeDialogWaitingAdmin  } from "../../service/DialogWatingAdminService";

const Login =() =>{
 
    const {loginAuth} = useAuth();

    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const [validUser, setvalidUser] = useState("");
    const [validPass, setValidPass] = useState("");

    const resetValid = () =>{
        setvalidUser("");
        setValidPass("");
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); // Ngăn chặn hành động mặc định của form (gửi dữ liệu và tải lại trang)
        // Xử lý dữ liệu form bằng JavaScript ở đây
        resetValid();
        let isValid = false;
        if(username === ""){
            setvalidUser("Bạn chưa nhập tài khoản");
            isValid = true;
        }
        if(password === ""){
            setValidPass("bạn chưa nhập mật khẩu");
            isValid = true;
        }

        if(isValid)
            return;

        openDialogWaitingAdmin();
        try{
            const response = await axios.post(process.env.REACT_APP_HOST + '/login', 
                {
                    username : username,
                    password: password
                },
                {
                    headers: {
                        'Content-Type': 'application/json' // Đảm bảo kiểu nội dung là JSON
                    }
                }
            );

            if(response.data.type === 2){
                await loginAuth(response.data.token, response.data.user);
                closeDialogWaitingAdmin();
            }
            
        }catch(error){
            if (error.response) {
                // Lỗi xảy ra do phản hồi từ server
                if(error.response.data.type === 0){
                    setvalidUser(error.response.data.message);
                }else if(error.response.data.type === 1){
                    setValidPass(error.response.data.message);
                }
            } else {
                // Lỗi khác
                console.error('Error:', error.message);
            }
        }


    }

    return (
        <>
            <div className="container  d-flex justify-content-center align-items-center vh-100">
                <div className="form-login">
                    <div className="pt-5 pb-5">
                        <div className="logo d-flex justify-content-center align-items-center">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="icon-user d-flex justify-content-center align-items-center mb-2">
                            <img src={iconUser} alt="iconUser" />
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="d-flex justify-content-center align-items-center p-2">
                                <div className="col-12 col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9 ">
                                    <div className="mb-4">
                                        <input type="text" className="form-control" placeholder="Username" onChange={(e) => setUserName(e.target.value)}/>
                                        <div className="form-text mx-1 text-custom-red">{validUser}</div>
                                    </div>
                                    <div className="mb-4">
                                        <input type="password" className="form-control" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                        <div className="form-text mx-1 text-custom-red">{validPass}</div>
                                    </div>

                                    <button type="submit" className="bt-login  w-100" >Login</button>
                                </div>
                            </div>
                        </form>
                        <div className="row d-flex justify-content-center align-items-center mt-3">
                            <div className="col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <div>
                                    <div className="bt-icon d-flex justify-content-center align-items-center">
                                        <img src={iconGoogle} alt="icon_google" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <div>
                                    <div className="bt-icon d-flex justify-content-center align-items-center">
                                        <img src={iconApple} alt="icon_google" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <div>
                                    <div className="bt-icon d-flex justify-content-center align-items-center">
                                        <img src={iconFace} alt="icon_google" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        

    );
}

export default Login;