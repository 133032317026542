import React from "react";
import "./Background.scss"; // Import CSS if needed
import rect from "../../../assets/back_ground.jpg";

const Background = () => {
  return (
    <div className="background ">
      <img src={rect} alt="background" />
    </div>
  );
};

export default Background;
