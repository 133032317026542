import React,  { forwardRef, useState }  from 'react';
import "./Filter.scss";
import iconArrow from '../../../assets/icons/icon_arrow.png';

const Filter = forwardRef(({ isOpenFilter, toggleSidebar }, ref) => {

  const [isOpenSize, setIsOpenSize] = useState(false);
  const handleOpenSize = () => {   
    setIsOpenSize(prev => !prev);
  };

  const [isOpenColor, setIsOpencolor] = useState(false);
  const handleOpencolor = () => {   
    setIsOpencolor(prev => !prev);
  };


  return (
    <>
      <button className="toggle-button" onClick={toggleSidebar}>  ☰ </button>
      <div ref={ref} className={`sidebar-guest ${isOpenFilter ? 'open' : ''}`}>
        <h3>New Arrivals</h3>
        <ul>
          <li className='justify-content-between d-flex align-items-center'>
            <p>Prize</p>
            <img src={iconArrow} alt='iconArrow' className='icon-arrow' />
          </li>
          <li>
            <div className='w-100 justify-content-between d-flex align-items-center' onClick={handleOpencolor}>
              <p>Colors</p>
              <img src={iconArrow} alt='iconArrow' className='icon-arrow' />
            </div>
            <div className={`w-100 horizontal-container mb-2 mt-2 ${isOpenColor ? 'd-none' : '' }` }>
              <p>Black</p>
              <p>White</p>
              <p>Red</p>
              <p>Orange</p>
            </div>
          </li>
          <li className='justify-content-between d-flex align-items-center'>
            <p>Sale</p>
            <img src={iconArrow} alt='iconArrow' className='icon-arrow' />
            </li>
          <li>
            <div className='w-100 justify-content-between d-flex align-items-center' onClick={handleOpenSize}>
              <p>Size</p>
              <img src={iconArrow} alt='iconArrow' className='icon-arrow' />
            </div>
            <div className={`w-100 horizontal-container mb-2 mt-2 ${isOpenSize ? 'd-none' : '' }` }>
              <p>S</p>
              <p>M</p>
              <p>L</p>
              <p>XL</p>
              <p>XXL</p>
            </div>
          </li>
        </ul>
        <button className='bt-filter'>Apply</button>
      </div>
    </>
  );
});

export default Filter;