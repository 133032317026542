import React, {useEffect, useRef, useState, useContext} from "react";
import "./Header.scss";
import logo from "../../../assets/logo.png";
import user from "../../../assets/icons/icon_user.png";
import icon_cart from "../../../assets/icons/icon_cart.png";
import Search from "../Search/Search";
import { CartContext } from "../../../contexts/CartContext";
import { useNavigate } from 'react-router-dom';

const Header = () => {


  const ref_width= useRef(null);
  const { cart } = useContext(CartContext);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const navigate = useNavigate();


  useEffect(()=>{
    if (ref_width.current) {
      const element = ref_width.current;
      const { width, height } = element.getBoundingClientRect();

      const widthAdjustment = width / 2;
      element.style.right = `-${widthAdjustment}px`;

      const heightAdjustment = height / 2;
      element.style.bottom = `-${heightAdjustment}px`;
    }
  }, [])

  useEffect(()=>{
      if(cart){
        setQuantity(cart.length);
      }
  }, [cart])

  const toggleNav = () => {
    setIsNavVisible(prevState => !prevState); // Đảo ngược trạng thái
  };

  const handleClickUSer = ()=>{
    navigate('/login');
  }

  const [quantity, setQuantity] = useState(0);

  return (
    <header className="header-guest">
      <div className="container-fluid">
        <div className="row align-items-center custom-height margin-header">
          <div className="col-xl-2 col-md-2 col-lg-2 col-sm-3 col-3 custom-height">
            <a href="/">
              <img src={logo} alt="logo" className="logo" />
            </a>
          </div>
          <div className={`col-xl-5 col-lg-5 col-md-5 title-nav-bar ${isNavVisible ? "nav-visible" : "nav-hidden"}`}>
            <nav>
              <ul className="nav">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/collection">
                    Collection
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact">
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-8 col-8">
            <nav>
              <ul className="nav justify-content-end align-items-center">
                <li className="nav-item">
                  <Search />
                </li>
                <li className="nav-item">
                  <img src={user} alt="Icon" className="icon" onClick={handleClickUSer} />
                </li>
                <li className="nav-item position-relative">
                  <a href="/cart"><img src={icon_cart} alt="Icon" className="icon" /></a>
                  <p ref={ref_width}  className="cart-quantity">{quantity}</p>
                </li>
                <li className="nav-item">
                  <p className="language">VI</p>
                </li>
                <li className="nav-item">
                  <p className="language">EN</p>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-sm-1 col-1 toggle-header">
            <button onClick={toggleNav}>  ☰ </button>
          </div>

        </div>
      </div>
    </header>
  );
};

export default Header;
