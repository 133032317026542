import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import DialogWaitingAdmin from '../components/Dialog/DialogWaitingAdmin/DialogWaitingAdmin';

// Biến lưu trữ trạng thái của Dialog và các hàm liên quan
let dialogData = {
    isOpen: false,
  };
  
let setDialogData;

// Hàm để hiển thị Dialog
export const openDialogWaitingAdmin = () => {
    setDialogData({
      isOpen: true,
    });
};

// Hàm để đóng Dialog
export const closeDialogWaitingAdmin = () => {
    setDialogData({
      isOpen: false,
    });
};


const DialogWaitingAdminServie = () =>{
    const [localData, setLocalData] = useState(dialogData);
    setDialogData = setLocalData; // Gán setDialogData để cập nhật trạng thái
    if (!localData.isOpen) 
        return null; // Không hiển thị khi isOpen = false

    return ReactDOM.createPortal(
        <DialogWaitingAdmin />,
        document.body
    )
}

export default DialogWaitingAdminServie;