import React from 'react';
import './DialogWaiting.scss';
import icon_success from '../../../assets/icons/icon_success.png';

const DialogWaiting = ({title, message,  onClose, isSuccess }) => {

    return (
        <div  className='dialog-waiting'>
            <div className='dialog'>
                <h2>{title}</h2>
                {isSuccess ? (
                    <div className='icon-success'>
                        <img src={icon_success} alt='icon_success' />
                        <p>{message}</p>
                    </div>
                ) : (
                    <div className="loader"></div>
                )}
                <div className='actions'>
                    <button className='button' onClick={onClose}> OK </button>
                </div>
            </div>
        </div>
    );
};


export default DialogWaiting;
