import React from "react";
import ListCollection from "../../components/Admin/ListCollection/ListCollection";

const CollectionAdmin = () =>{
    return (
        <>
            <ListCollection />
        </>
    );
}

export default CollectionAdmin;